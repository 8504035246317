<template>
  <div>
    <window-header :minimize="false"></window-header>
    <nav
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <r-progressbar
        :text="progressbarText"
        :value="progressbar"
        v-if="loading"
      ></r-progressbar>
      <template v-else>
        <button
          @click="importar"
          class="button button-fill"
          style="min-width: 100px"
          v-if="selectedModel"
        >
          <fa-icon icon="upload" />
          <span>Importar</span>
        </button>
        <select @change="loadModel($event.target.value)">
          <option disabled hidden selected>Seleccionar tabla...</option>
          <option v-for="model in models" :key="model.name" :value="model.name">
            {{ model.title }}
          </option>
        </select>
        <button
          @click="exportImportableCSVStructure"
          class="button button-fill"
          style="font-size: 0.9em; float: right; min-width: 180px"
          v-if="selectedModel"
        >
          <fa-icon icon="table" />
          <span>Descargar estructura</span>
        </button>
      </template>
    </nav>
    <div style>
      <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
    </div>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import { HotTable } from "@handsontable/vue";
import Handsontable from "handsontable";

export default {
  components: {
    HotTable,
  },
  inject: ["app"],
  mixins: [WindowMixin],
  computed: {
    colHeadersHt() {
      var self = this;
      return function (col) {
        if (typeof self.columnsData[col] == "undefined") return "-";
        let h = self.columnsData[col];
        return (
          "<span title='" +
          (h.description || "") +
          "'>" +
          (h.title || h.name) +
          "</span>"
        );
      };
    },
    columnsHt() {
      var self = this;
      var cols = [];
      for (let c of self.columnsData) {
        var col = {
          data: c.name,
          renderer: function (
            instance,
            td,
            row,
            col,
            prop,
            value,
            cellProperties
          ) {
            Handsontable.renderers.BaseRenderer.apply(this, arguments);
            if (self.errores[row] && self.errores[row][prop]) {
              td.classList.add("wrong");
              td.setAttribute("title", self.errores[row][prop]);
            } else {
              td.classList.remove("wrong");
              td.removeAttribute("title");
            }
            if (
              self.duplicados.includes(row) &&
              prop == self.selectedModel.primary
            ) {
              td.classList.add("warning");
              td.setAttribute("title", "Registro duplicado");
            }
            if (cellProperties.type == "numeric") {
              Handsontable.renderers.NumericRenderer.apply(this, arguments);
            } else if (cellProperties.type == "date2") {
              Handsontable.renderers.Date2Renderer.apply(this, arguments);
            } else {
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            //td.textContent = value;
            return td;
          },
        };
        if (c.type == "float") {
          col.type = "numeric";
          col.numericFormat = {
            pattern: "0,0.000",
            culture: "es-ES",
          };
          col.language = "es-ES";
        } else if (c.type == "boolean") {
          col.type = "checkbox";
          col.checkedTemplate = "1";
          col.uncheckedTemplate = "0";
          col.allowInvalid = false;
          col.allowEmpty = false;
        } else if (c.type == "date" || c.type == "datetime") {
          col.type = "date2";
          /*window.$.extend(true, col, {
            type: "date",
            dateFormat: "YYYY-MM-DD",
            correctFormat: true,
            renderer: function() {
                  //if (value) value = APP.UTILS.formatDate(value);
                  //Handsontable.renderers.DateCell.renderer.apply(this, arguments);
                },
            sortFunction: function (sortOrder) {
              return function (a, b) {
                if (sortOrder) return a[1] >= b[1];
                else return a[1] <= b[1];
              };
            },
          });*/
        }
        cols.push(col);
      }
      return cols;
    },
    htSettings() {
      var self = this;
      return {
        licenseKey: "non-commercial-and-evaluation",
        rowHeaders: true,
        fillHandle: {
          direction: "vertical",
          autoInsertRow: true,
        },
        width: 800, //660,
        height: 393,
        readOnly: self.loading,
        manualColumnResize: true,
        columns: self.columnsHt,
        colHeaders: self.colHeadersHt,
        colWidths: 100,
        data: self.items,
        currentRowClassName: "currentRow",
        afterChange: self.afterChange,
        minRows: 17,
        minSpareRows: 1,
        rowHeights: 20,
        contextMenu: {
          items: {
            row_below: { name: "Insertar fila" },
            remove_row: {
              name: "Eliminar fila",
              callback(key, selection, clickEvent) {
                let startRow = selection[0].start.row;
                let endRow = selection[0].end.row;
                let N = 1 + endRow - startRow;
                // Eliminar filas
                var val = self.$refs.hotTableComponent.hotInstance.getSourceData();
                val.splice(startRow, N);
                // Eliminar errores
                let errores = self.errores;
                let duplicados = self.duplicados;
                duplicados = duplicados.filter(
                  (v) => v < startRow || v > endRow
                );
                self.duplicados = duplicados.map((v) =>
                  v > endRow ? v - N : v
                );
                let errores_copy = JSON.parse(JSON.stringify(errores || {}));
                for (let ie in errores) {
                  if (ie >= startRow && ie <= endRow) {
                    delete errores[ie];
                  } else if (ie > endRow) {
                    delete errores[ie];
                    errores[ie - N] = errores_copy[ie];
                  }
                }
                self.$emit("removeRow");
              },
            },
          },
        },
        beforePaste: (data, coords) => {
          for (let row in data) {
            data[row].length = self.columnsData.length;
          }
        },
      };
    },
  },
  data: function () {
    return {
      title: "Importar",
      items: [],
      columnsData: [],
      loading: false,
      progressbar: 0,
      progressbarText: "",
      duplicados: [],
      errores: [],

      models: [],
      selectedModel: null,
    };
  },
  methods: {
    afterChange(changes, source) {
      var self = this;
      if (source == "loadData") {
        return;
      }
      var h = self.$refs.hotTableComponent.hotInstance;
      var val = h.getSourceData();
      for (var i = changes.length - 1; i >= 0; i--) {
        var change = changes[i];
        var row = change[0];
        var prop = change[1];
        var oldVal = change[2];
        var newVal = change[3];
        let errores = self.errores;
        let duplicados = self.duplicados;
        val[row] = val[row] || {};
        if (
          !Object.keys(val[row]).some(
            (el) => el != "_op" && val[row][el] != null
          )
        ) {
          val.splice(row, 1);
          if (errores) self.$delete(errores, row);
          duplicados = duplicados.filter((v) => v < row || v > row);
          self.duplicados = duplicados.map((v) => (v > row ? v - 1 : v));
          continue;
        }

        if (errores && errores[row] && errores[row][prop]) {
          self.$delete(errores[row], prop);
        }
        if (duplicados.includes(row) && prop == self.selectedModel.primary) {
          let dix = duplicados.indexOf(row);
          if (dix != -1) duplicados.splice(dix, 1);
        }
      }
      //Eliminar filas vacías
      val = val.filter((v, i) =>
        Object.keys(v).some((el) => el != "_op" && v[el] != null)
      );
      h.render();
      //self.setValue(val.length ? [...val] : false);
      //window.console.log(self.form);
      //self.form.$forceUpdate();
      //self.onChange();
    },
    exportImportableCSVStructure() {
      var self = this;
      window.open(
        window.DB.server +
          self.selectedModel.name +
          "?" +
          window.$.param({
            action: "exportImportableCSVStructure",
            token: self.app.session.token,
          }),
        "_blank"
      );
    },
    importar() {
      var self = this;
      var h = this.$refs.hotTableComponent.hotInstance;
      var gridData = h.getSourceData();
      var cleanedGridData = [];
      gridData.forEach(function (object, rowKey) {
        if (!h.isEmptyRow(rowKey)) {
          cleanedGridData.push(object);
        }
      });
      if (!cleanedGridData.length) {
        self.app.toast("Introduzca al menos un registro", "error");
        return;
      }

      var sobrescribirDuplicados = true;
      self.errores = [];
      self.duplicados = [];
      function validarRegistro(allData, index) {
        window.DB.action(self.selectedModel.name, "validate", {
          data: {
            data: allData[index],
          },
        })
          .then(function (res) {
            if (Object.keys(res.errores).length) {
              //window.console.log(index, res);
              self.errores[index] = res.errores;
            }
            if (res.duplicado) {
              self.duplicados.push(index);
            }
            index++;
            self.progressbar = Math.floor((index * 100) / allData.length);
            if (index < allData.length) {
              validarRegistro(allData, index);
            } else {
              if (self.errores.length) {
                var msg = "Se han encontrado errores";
                /*$.each(errores, function(i, err) {
                msg += "Línea " + parseInt(i) + ":\n";
                $.each(err, function(campo, texto) {
                  msg += "\t" + campo + ": " + texto + "\n";
                });
              });*/
                //window.console.log(Object.keys(self.errores[0])[0], h.propToCol(Object.keys(self.errores[0])[0]), Object.keys(self.errores)[0]);

                let h = self.$refs.hotTableComponent.hotInstance;
                window.h = h;
                window.errores = self.errores;
                let rr = parseInt(Object.keys(self.errores)[0]);
                let cc = h.propToCol(Object.keys(self.errores[rr])[0]);
                h.scrollViewportTo(rr + 1, cc);
                self.app.toast(msg, "error");
                self.loading = false;
              } else {
                self.app.confirm(
                  "¿Está seguro de que desea importar " +
                    cleanedGridData.length +
                    " registros?",
                  function () {
                    self.progressbarText = "Importando";
                    self.progressbar = 0;
                    if (self.duplicados.length) {
                      self.app.confirm(
                        "Existen registros duplicados. ¿Desea sobreescribirlos? En caso contrario serán ignorados.",
                        function () {
                          sobrescribirDuplicados = true;
                          importarRegistro(cleanedGridData, 0);
                        },
                        function () {
                          sobrescribirDuplicados = false;
                          importarRegistro(cleanedGridData, 0);
                        }
                      );
                    } else {
                      importarRegistro(cleanedGridData, 0);
                    }
                  },
                  function () {
                    self.loading = false;
                  }
                );
              }
            }
          })
          .catch(function (res) {
            self.app.toast("Ocurrió un error inesperado.");
            self.loading = false;
          });
      }
      function importarRegistro(allData, index) {
        if (!sobrescribirDuplicados && self.duplicados.includes(index)) {
          // Ignorar
          var p = Promise.resolve();
        } else {
          p = window.DB.save(self.selectedModel.name, {
            itemId: self.duplicados.includes(index)
              ? allData[index][self.selectedModel.primary]
              : null,
            data: allData[index],
            options: { validate: false }, //TODO!!
          });
        }
        p.then(function (res) {
          index++;
          self.progressbar = Math.floor((index * 100) / allData.length);
          if (index < allData.length) {
            importarRegistro(allData, index);
          } else {
            self.app.toast("¡Datos importados con éxito!");
            self.progressbar = 100;
            self.progressbarText = "Importación completa";
            if (self.htSettings && self.winParent) {
              self.winParent.instance.initRemoteData();
            }
            self.$emit("close");
          }
        }).catch(function (res) {
          self.app.toast("Ocurrió un error inesperado", "error");
          window.console.log(res);
        });
      }

      self.loading = true;
      self.progressbar = 0;
      self.progressbarText = "Validando";
      validarRegistro(cleanedGridData, 0);
    },
    loadModel(model) {
      var self = this;
      self.selectedModel = self.models.find((m) => m.name == model);
      window.DB.action(model, "getImportableFields")
        .then(function (res) {
          self.columnsData = res.fields;
          self.errores = [];
          self.duplicados = [];
          self.items = [];
        })
        .catch(function (e) {
          window.console.log(e);
        });
    },
  },
  mounted() {
    var self = this;
    window.DB.action("configuracion", "getModels").then(function (res) {
      self.models = res.data;
      self.models.sort((a, b) => (a.title > b.title ? 1 : -1));
    });
  },
};
</script>
